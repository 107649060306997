




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { copyToClipboard } from 'quasar'

@Component({
  name: 'IntegrationManagementSectionFormField',
  components: {
    IconCopy: () => import('@/components/UI/icons/IconCopy.vue'),
  },
})
export default class IntegrationManagementSectionFormField extends Vue {
  /**
   * Component model. Updates with group id.
   */
  @Prop({
    type: String,
    required: true,
  }) readonly value!: string

  /**
   * Label to use above input.
   */
  @Prop({
    type: String,
    required: true,
  }) readonly label!: string

  /**
   * Put component in loading state. Default false.
   */
  @Prop({
    type: Boolean,
    default: false,
  }) readonly loading!: false

  /**
   * Put component in readonly state. Default false.
   * If true, component will not display errors,
   * but will grow to fit all of its model on the screen.
   */
  @Prop({
    type: Boolean,
    default: false,
  }) readonly readonly!: false

  /**
   * Error string to display by the component. Puts component in error mode.
   * This prop will override the display of any internal errors.
   * Component will try and update prop on internal error, use 'sync' modifier.
   */
  @Prop({
    type: String,
    default: '',
  }) readonly error!: string

  internalError = '' // error that occured inside the component
  maxLength = 200 // server default, see 'created' hook

  /**
   * Returns whether there is an error in the component.
   * Either an internal one, or passed via prop.
   */
  get hasError (): boolean {
    return !!this.error || !!this.internalError
  }

  /**
   * Returns error message if there is one.
   * Either an internal one, or passed via prop.
   */
  get errorMsg (): string {
    return this.error || this.internalError
  }

  created () {
    const maxLength = window.FEATURES.max_url_length
    // why undef? because it can technically be 0
    maxLength !== undefined && (this.maxLength = maxLength)
  }

  /**
   * Updates component model and handles internal error if needed
   * @param v user input
   */
  handleInput (v: string): void {
    // update the model anyway
    this.$emit('input', v)

    // check for errors
    if (v.length <= this.maxLength) {
      this.internalError = ''
    } else {
      const key = 'common.validationErrors.maxLength'
      const params = { to: this.maxLength }
      this.internalError = this.$t(key, params).toString()
      this.$emit('update:error', this.internalError)
    }
  }

  /**
   * Simple copy to clipboard and display notification.
   */
  copyKey (): void {
    if (!this.value) return

    copyToClipboard(this.value)
    this.$q.notify({
      message: this.$t('common.copied').toString(),
      color: 'primary',
      textColor: 'white',
      position: 'top',
      badgeColor: 'white',
      badgeTextColor: 'primary',
      timeout: 1000,
    })
  }
}
